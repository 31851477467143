import { useContext } from "react";
import BaseLayout from "../components/global/BaseLayout";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";
import GalleryContent from "../components/Gallery/GalleryContent";



export default function Paint() {

    const { rpdata } = useContext(GlobalDataContext)
    return (
        <BaseLayout PageName="paint">
            <div className="md:max-w-full w-full">
                <TransparentHeader
                    headertitle={"Paint"}
                    Subheader={"Paint"}
                    bgimg={`${rpdata?.stock?.[16]}`}
                />

                <div className="pb-32">
                    <div className="text-center py-5">
                        <h2 className="text-center pt-[100px]">Our Painting Projects</h2>
                    </div>
                    <GalleryContent galleryImages={rpdata?.stock?.slice(16)} />
                </div>

            </div>
        </BaseLayout>

    )
}